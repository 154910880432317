import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IrradianceState } from './type';
import { getFluxData } from 'components/DisplayEnergy/api';
import { errorResponseHandler } from 'store/api/AxiosError';
import { AxiosError } from 'axios';
import { CustomError } from 'store/api/types';
import { changePanel, fetchRoofData } from '../RoofDataSlice/roofDataSlice';
import { RootState } from 'store';

const initialState: { data: IrradianceState } = {
	data: {
		selectedMonth: 12,
		showIrradiance: false,
		irradianceImages: { sunroof: [], nearmap: [], vexcel:[] },
		showProgress: false,
		showPanels: true,
		error: null
	},
};

export const fetchIrradianceImages = createAsyncThunk('irradiance/fetchImages',
	async ({ uuid, imagerySource }: FetchIrradianceImagesArgs, thunkApi) => {
		try {
			const state = (thunkApi.getState() as RootState).Irradiance.data;
			const irradianceImages = { ...state.irradianceImages };
			irradianceImages[imagerySource] = await getFluxData(uuid) as string[];
			return irradianceImages;
		} catch (error) {
			console.log('error irreadiance slice', error);
			throw thunkApi.rejectWithValue(errorResponseHandler(error as AxiosError)) as unknown as CustomError;
		}
	}
);

export const IrradianceSlice = createSlice({
	name: 'irradiance',
	initialState,
	reducers: {
		setIrrandiance(state, action: PayloadAction<Partial<IrradianceState>>) {
			let { selectedMonth, showPanels } = state.data;
			if (action.payload.showIrradiance) {
				selectedMonth = initialState.data.selectedMonth;
				showPanels = true;
			}
			state.data = { ...state.data, selectedMonth, showPanels, ...action.payload };
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchRoofData.fulfilled, (state) => {
				state.data = {
					...initialState.data,
				};
			})
			.addCase(fetchIrradianceImages.pending, (state) => {
				state.data.showProgress = true;
			})
			.addCase(fetchIrradianceImages.fulfilled, (state, action) => {
				state.data = {
					...state.data,
					showProgress: false,
					irradianceImages: action.payload,
					error: null
				};
			})
			.addCase(fetchIrradianceImages.rejected, (state, action) => {
				state.data = {
					...initialState.data,
					error: action.error.message ?? null
				};
			}).addCase(changePanel.pending, (state, action) => {
				const { switchingSource } = action.meta.arg;
				if(switchingSource) state.data.showIrradiance= false;
			});
	},
});

export const { setIrrandiance } = IrradianceSlice.actions;

export default IrradianceSlice.reducer;
