import Irradiance from 'components/Irradiance';
import MonthButtons from 'components/Irradiance/MonthButtons';
import Loader from 'components/Loader';
import MoveButton from 'components/MoveButton';
import ThreeDImage from 'components/ThreeDImage';
import ZoomButtons from 'components/ZoomButtons';

import { useRefDispatch } from 'contexts/RefContext';
import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';
import { useEffect, useLayoutEffect, useMemo, useRef, useState, useCallback } from 'react';
import { Layer, Stage } from 'react-konva';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { setKonvaStagePosition, setZoomLevel } from 'store/slices/ToolSlice';
import { StripProduction } from './Editor/style';
import PanelDrawer from './Raster/PanelDrawer';
import { FlexDiv, MoveZoomWrapper, StageWrap, StripProductionWrap } from './styles';
import { applyDisplacementBound, getNewPositonByApplyiingScaleFromCenter, zoomAtAPosition } from './utils/util';
import { DEFAULT_KONVA_STAGE_POSITION, DESIGN_MODES, FULL_VIEW_POSITION_SHIFT_IN_PIXELS, STANDARD_IMAGERY_SIZE } from '../../constants';

import Obstructions from '../Obstructions';
import FacetGroup from './Group/FacetGroup';
import SetbackArea from './Facet/SetbackArea';
import SimplifiedConcaveHullLines from './Facet/SimplifiedConcaveHullLines';
import RawConcaveHullLines from './Facet/RawConcaveHullLines';
import CustomRectProps from './Panel/CustomRect';
import CustomPanel from './Panel/CustomPanel';
import ModeStripContainer from './Editor/ModeStrip/ModeStripContainer';

import useKonvaStageDimensions from 'hooks/useKonvaStageDims';
import { fetchIrradianceImages } from 'store/slices/IrradianceSlice';
import { useKonvaImageDimensions } from 'contexts/ToolDimensionsAndScaleContext';

function Tool() {
	const layerRef = useRef<Konva.Layer | null>(null);
	const { mode , uuid, imagerySource, imgHeight, enableEditFacet } = useSelector((state: RootState) => state.roofData.data);
	const stageRef = useRef<Konva.Stage | null>(null);
	const stageWrapRef = useRef<HTMLDivElement | null>(null);
	const canvasWrapRef = useRef<HTMLDivElement | null>(null);
	const [threeDModelReady, setThreeDModelReady] = useState(false);
	const [lastDist, setLastDist] = useState<number | null>(null);
	const [lastCenter, setLastCenter] = useState<{x:number, y:number} | null>(null);

	const toolEditState = useSelector((state: RootState) => state.toolNewPostions.data);
	const { threeDModelEnabled, zoomLevel, konvaStagePosition, moveStage, drawModeEnabled, fullScreenEnabled, groundMountEnabled } = toolEditState;
	const { enableAddPanel, enableMultipleAddPanel } = useSelector((state: RootState) => state.panelSlice.data);

	const refDispatch = useRefDispatch();
	const { showIrradiance, showProgress, irradianceImages, error } = useSelector((state: RootState) => state.Irradiance.data);
	const showLoader = (threeDModelEnabled && !threeDModelReady) || (showProgress && showIrradiance);
	const dispatch = useDispatch<AppDispatch>();
	const konvaStageDims = useKonvaStageDimensions({ stageWrapRef });
	const konvaImageDims = useKonvaImageDimensions();

	const handleMouseScroll = (event: KonvaEventObject<WheelEvent>) => {
		event.evt.preventDefault();
		const stage = stageRef.current;
		if (!stage) return;
		const type = event.evt.deltaY > 0 ? 'out' : 'in';
		const updatedZoomLevelAndPositon = zoomAtAPosition(stage, stage.getPointerPosition()!, type);
		if (!updatedZoomLevelAndPositon) return;
		dispatch(setZoomLevel(updatedZoomLevelAndPositon));
	};

	const adjustStageDimsANdPostion = useCallback(() => {
		const stage = stageRef?.current;
		if (!stage) return;
		const { width: stageWidth, height: stageHeight } = konvaStageDims;
		stage.height(stageHeight);
		stage.width(stageWidth);
		let offset = { x: 0, y: 0 };

		let position = { ...DEFAULT_KONVA_STAGE_POSITION };
		let konvaZoomScale = imgHeight / STANDARD_IMAGERY_SIZE;

		const isDesktopView = stageWidth > STANDARD_IMAGERY_SIZE;
		const adjustDisplayEnergyLeftSidePanel = fullScreenEnabled && isDesktopView;

		if (adjustDisplayEnergyLeftSidePanel) {
			konvaZoomScale = (stageHeight / konvaImageDims.height) * 1.5;
			const posShift = FULL_VIEW_POSITION_SHIFT_IN_PIXELS;
			offset = { x: -posShift / konvaZoomScale, y: 100/konvaZoomScale };
		} else {
			position = getNewPositonByApplyiingScaleFromCenter(stage, konvaZoomScale);
		}
		stage.offset(offset);
		dispatch(setZoomLevel({ scaleBy: konvaZoomScale, position }));
	}, [konvaStageDims, imgHeight, fullScreenEnabled, konvaImageDims, dispatch]);

	useLayoutEffect(() => {
		const canvasWrapElement = canvasWrapRef.current;
		if (!canvasWrapElement) {
			return;
		}
		canvasWrapElement.style.width = `${konvaStageDims.width}px`;
		canvasWrapElement.style.height = `${konvaStageDims.height}px`;
	}, [konvaStageDims]);

	useEffect(() => {
		refDispatch({ type: 'ADD_KONVA_REF', payload: { ref: stageRef } });
	}, [stageRef, refDispatch]);

	useEffect(() => {
		if (layerRef.current) {
			window.parent.postMessage({ id: 'SOLARQUOTEEMBED_DATA_LOADED' }, '*');
			layerRef.current.getCanvas()._canvas.id = 'SOLARQUOTEEMBED-CANVAS';
		}
	},[]);

	useEffect(() => {
		if (!threeDModelEnabled) {
			adjustStageDimsANdPostion();		
		}
	}, [adjustStageDimsANdPostion, threeDModelEnabled, threeDModelReady]);

	useEffect(() => {
		if (!threeDModelEnabled) {
			setThreeDModelReady(false);
		}
	}, [threeDModelEnabled]);

	useEffect(()=>{
		if (!error && !showProgress && !irradianceImages[imagerySource]?.length && mode == DESIGN_MODES.DEFAULT) {
			dispatch(fetchIrradianceImages({ uuid, imagerySource }));
		}

	},[dispatch, irradianceImages, mode, uuid, showProgress, imagerySource, error]);

	const cursorStyle = useMemo(() => {
		switch (true) {
		case moveStage:
			return 'grab';
		case drawModeEnabled:
			return 'crosshair';
		default:
			return 'default';
		}
	}, [drawModeEnabled, moveStage]);

	function setDragBoundToStage(pos: Vector2d) {
		if (!stageRef.current) return pos;
		const newPos = applyDisplacementBound(stageRef.current, pos);
		dispatch(setKonvaStagePosition({ position: newPos }));
		return pos;
	}

	const handlePointerMove = (e: Konva.KonvaEventObject<TouchEvent>) => {
		e.evt.preventDefault();
		const stage = stageRef.current;
		if (!stage || e.evt.touches.length !== 2) return;
		
		const touch1 = e.evt.touches[0];
		const touch2 = e.evt.touches[1];
		
		const dist = Math.hypot(
			touch1.clientX - touch2.clientX,
			touch1.clientY - touch2.clientY
		);
		
		const center: Vector2d = {
			x: (touch1.clientX + touch2.clientX) / 2,
			y: (touch1.clientY + touch2.clientY) / 2,
		};
		
		if (lastDist !== null) {
			const zoomThreshold = 3;
			if (Math.abs(dist - lastDist) > zoomThreshold) {
				const type = dist > lastDist ? 'in' : 'out';
				const updatedZoom = zoomAtAPosition(stage, center, type, 0.05);
				if (updatedZoom) {
					dispatch(setZoomLevel(updatedZoom));
				}
			}
			else if(lastCenter !== null) {
				const deltaX = center.x - lastCenter.x;
				const deltaY = center.y - lastCenter.y;
				stage.position({
					x: stage.x() + deltaX,
					y: stage.y() + deltaY
				});
				stage.batchDraw();
			}
		}
		setLastDist(dist);
		setLastCenter(center);
	};
	
	const handleTouchEnd = () => {
		setLastDist(null);
		setLastCenter(null);
	};

	return (
		<StageWrap ref={stageWrapRef}>
			<FlexDiv ref={canvasWrapRef}>
				<ModeStripContainer/>
				{(showIrradiance && !threeDModelEnabled) && <MonthButtons />}

				{!threeDModelReady &&
					<Stage
						scaleX={zoomLevel}
						scaleY={zoomLevel}
						ref={stageRef}
						draggable={moveStage && lastDist === null}
						onWheel={handleMouseScroll}
						x={konvaStagePosition.x }
						y={konvaStagePosition.y }
						onContextMenu={(e) => {
							e.evt.preventDefault();
						}}
						onTouchMove={handlePointerMove}
						onTouchEnd={handleTouchEnd}
						onDragStart={() => {
							if (!stageRef.current || !moveStage) return;
							stageRef.current.container().style.cursor = 'grabbing';
						}}
						onDragEnd={() => {
							if (!stageRef.current || !moveStage) return;
							stageRef.current.container().style.cursor = 'grab';
						}}
						dragBoundFunc={setDragBoundToStage}
						style={{ cursor: cursorStyle}}
					>
						<Layer ref={layerRef} >
							<>
								<PanelDrawer showAzimuthArrows={mode === DESIGN_MODES.NREL_MANUAL || groundMountEnabled }/>
								{showIrradiance && <Irradiance layerRef={layerRef} />}
								<FacetGroup />
								<Obstructions />
								{!enableEditFacet && <SetbackArea />}
								<SimplifiedConcaveHullLines/>
								<RawConcaveHullLines/>
								{(enableAddPanel && !enableMultipleAddPanel) && <CustomPanel />}
								<CustomRectProps />
							</>
						</Layer>
					</Stage>
				}
				{showLoader && <Loader styles={{ position: 'absolute', background: '#ffffff8f', top: '0px' }} />}

				{threeDModelEnabled && <div style={{ width: konvaStageDims.width, height: konvaStageDims.height, 
					position: showLoader && threeDModelEnabled ? 'absolute' : 'relative', }}>
					<ThreeDImage setThree3ModelReady={setThreeDModelReady} />
				</div>}
		
				<StripProductionWrap fullScreenEnabled={fullScreenEnabled}>
					<StripProduction />
				</StripProductionWrap>
				{!threeDModelEnabled &&
					<MoveZoomWrapper 
					// fullScreenEnabled={fullScreenEnabled} 
						className={showIrradiance ? 'show-irradiance zoomWrapper' : 'zoomWrapper'}>
						<MoveButton />
						<ZoomButtons />
					</MoveZoomWrapper>
				}
			</FlexDiv>
		</StageWrap>
	);
}

export default Tool;