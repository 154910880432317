enum API_ERROR_CODES {
  NON_EXISTENT_PANEL = 'E_INVALID_PANEL',
  SUNROOF_DESIGN_NOT_AVAILABLE = 'NOT_FOUND',
  NEARMAP_DESIGN_NOT_AVAILABLE = 'SURVEYS_NOT_FOUND',
  INVALID_ADDRESS = 'E_INVALID_ADDRESS',
  INVALID_MODE_CHECKOUT = 'E_INVALID_MODE_CHECKOUT',
  INVALID_IMAGERY_CHECKOUT = 'E_INVALID_IMAGERY_CHECKOUT',
}

enum PANEL_ENERGY_COLORS {
	HIGH = 'rgba(255, 209, 89, 0.5)',//'#FFD159',
	MEDIUM = 'rgba(255, 147, 43, 0.5)', //'#FF932B',
	LOW = 'rgba(222, 91, 18, 0.5)'
}

const PANEL_OFF_COLOR = 'rgba(131, 134, 141, 0.1)';
const PANEL_STROKE_COLOR = '#FFFFFF';
const STROKE_COLOR_FOR_USER_MAPPED_PANEL = '#FEB517';
const STROKE_COLOR_FOR_TEMP_ADDED_PANEL = '#2382ea';

const SEMI_TRANPARENT_BLACK_PANEL = 'rgba(0,0,0,0.4)';
const FULL_TRANPARENT_BLACK_PANEL = 'rgba(0,0,0,0.1)';

const FACET_HIGHLIGHT_COLOR = 'rgba(255, 255, 0, 0.3)';
const FACET_HIGHLIGHT_OFF_COLOR = 'rgba(0,0,0,0)';

const ENERGY_THRESHOLD_THAT_A_PANEL_MUST_HAVE = 480;

const MIN_PANELS_REQUIRED_FOR_DESIGN_TO_QUALIFY = 3;

const MIN_PANELS_REQUIRED_FOR_ROOF_SEGMENT_TO_QUALIFY = 2 ;
const MIN_PANELS_REQUIRED_FOR_ROOF_SEGMENT_TO_DISPLAY= 3 ;

const SOlAR_QOUTE_APP_NAME = 'SOLARQUOTE';
const SUBHUB_APP_NAME = 'SUBHUB';

enum PARENT_EVENTS {
	DESIGN_HAS_LESS_THEN_REQUIRED_PANELS = 'SOLARQUOTEEMBED_BAD_DESIGN',
	DESIGN_HAS_SUFFICIENT_PANELS = 'SOLARQUOTEEMBED_DESIGN_DATA_LOADED',
	SET_HOUSE = 'SOLARQUOTEEMBED_SET_HOUSE',
	PANEL_CHANGED = 'SOLARQUOTEEMBED_PANEL_CHANGED',
	SAVE_DESIGN_TRIGGER = 'SOLARQUOTEEMBED_SAVE_HOUSE_TRIGGERED',
	ENERGY_INPUTS_CHANGED = 'energyInputsChanged',
	FULL_VIEW_TOGGLE= 'FULL_VIEW_MODE',
	SAVE_DESIGN = 'SOLARQUOTEEMBED_SAVE_DESIGN',
	DESIGN_SYNCED = 'PARENT_SYNCED_WITH_TOOL',
	DESIGN_UNSYNCED = 'PARENT_UNSYNCED_WITH_TOOL',
}

const ENERGY_DERATE_RATE = 15;
// inverter  derate 4 and  groun_cov_ratio 0.4
const ENERGY_DERATE_RATE_FOR_NON_DEFAULT_MODES = 4.4;
const DEFAULT_ELECTRIC_UNIT_PRICE = 26.5;
const AZIMUTH_CONSTRAINT = 30;
const MONTHS = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec', 'Year'];
const MAX_ZOOM_LIMIT= 3;
const MAX_ZOOM_LIMIT_FOR_SAVE= 1.5;
const MIN_ZOOM_LIMIT= 0.25;
const DEFAULT_KONVA_SCALE = 1;
const DEFAULT_KONVA_STAGE_POSITION = { x: 0, y: 0 };
const IRRADIANCE_UPRATE_RATE = 15;
const UUUID_SEPERATOR = '@';

// values in inches
const SETBACK = {
	NONGUTTER: 18,
	GUTTER: 0,
	OBSTRUCTION: 0
};

enum IMAGERY_PROVIDER{
	NEARMAP= 'nearmap',
	SUNROOF= 'sunroof',
	VEXCEL = 'vexcel'
}

enum DESIGN_MODES {
	DEFAULT = 'DEFAULT',
  NREL_AUTOMATIC = 'NREL_AUTOMATIC',
  NREL_MANUAL = 'NREL_MANUAL',
}

const IMAGERY_PROVIDER_KEY = {
	[IMAGERY_PROVIDER.NEARMAP]: 'nearmap',
	[IMAGERY_PROVIDER.SUNROOF]: 'solarInfo',
	[IMAGERY_PROVIDER.VEXCEL]: 'vexcel',
};

enum KONVA_FACET_GROUP {
  EXISTING_GROUP_NAME = 'already-created',
  IN_PROGRESS_GROUP_NAME = 'in-process',
  GROUP_NAME_SEPARATOR = '@',
	CENTER_ARROW_ID_SUFFIX = 'centerarrow',
	LINE_ID_SUFFIX = 'line',
	PANEL_GROUP_SUFFIX = 'panels',
	AZIMUTH_SELECTOR_SUFFIX= 'azimuth-selector'
}

const BREAK_POINT_FOR_MOBILE_VIEW= 935;//(in px)
/*
	max-width of the dispaly energy panel in full view mode 
 	for screens >BREAK_POINT_FOR_MOBILE_VIEW
*/
const FULL_VIEW_POSITION_SHIFT_IN_PIXELS= 400;
const STANDARD_IMAGERY_SIZE = 600; //(600x600 px)

const PUERTI_RICO_STATE_CODE = 'PR';
const DEFAULT_SHADING_VAL = 14.08;
const PANEL_OVERLAP_THRESHOLD = 0.5;
const GROUND_MOUNT_DEFAULT_PITCH = 18;
const NORMAL_FACET_DEFAULT_PITCH = 20;

export {
	PANEL_ENERGY_COLORS,
	PANEL_OFF_COLOR,
	PANEL_STROKE_COLOR,
	ENERGY_THRESHOLD_THAT_A_PANEL_MUST_HAVE,
	MIN_PANELS_REQUIRED_FOR_DESIGN_TO_QUALIFY,
	PARENT_EVENTS,
	SOlAR_QOUTE_APP_NAME,
	ENERGY_DERATE_RATE,
	DEFAULT_ELECTRIC_UNIT_PRICE,
	SUBHUB_APP_NAME,
	AZIMUTH_CONSTRAINT,
	SEMI_TRANPARENT_BLACK_PANEL,
	FULL_TRANPARENT_BLACK_PANEL,
	MONTHS,
	MIN_PANELS_REQUIRED_FOR_ROOF_SEGMENT_TO_QUALIFY,
	MAX_ZOOM_LIMIT,
	MIN_ZOOM_LIMIT,
	MIN_PANELS_REQUIRED_FOR_ROOF_SEGMENT_TO_DISPLAY,
	DEFAULT_KONVA_SCALE,
	DEFAULT_KONVA_STAGE_POSITION,
	STROKE_COLOR_FOR_USER_MAPPED_PANEL,
	IRRADIANCE_UPRATE_RATE,
	IMAGERY_PROVIDER,
	IMAGERY_PROVIDER_KEY,
	FULL_VIEW_POSITION_SHIFT_IN_PIXELS,
	UUUID_SEPERATOR,
	KONVA_FACET_GROUP,
	BREAK_POINT_FOR_MOBILE_VIEW,
	DESIGN_MODES,
	API_ERROR_CODES,
	MAX_ZOOM_LIMIT_FOR_SAVE,
	SETBACK,
	FACET_HIGHLIGHT_COLOR,
	FACET_HIGHLIGHT_OFF_COLOR,
	ENERGY_DERATE_RATE_FOR_NON_DEFAULT_MODES,
	PUERTI_RICO_STATE_CODE,
	DEFAULT_SHADING_VAL,
	PANEL_OVERLAP_THRESHOLD,
	GROUND_MOUNT_DEFAULT_PITCH,
	NORMAL_FACET_DEFAULT_PITCH,
	STROKE_COLOR_FOR_TEMP_ADDED_PANEL,
	STANDARD_IMAGERY_SIZE
};
