import styled from 'styled-components';

export const ModalPopup = styled.div`
display: flex;
gap:2rem;
width: 100%;
height: 100%;
flex-direction: column;
justify-content: center;	
padding: 14px 14px 14px;
max-height: 306px;
overflow-y: auto;
box-sizing: border-box;
`;

export const ModalcontentInfo = styled.div`
	color: #2E384D;
	font-size: 1.25em;
	text-align:center;
	font-weight:600
`;
export const Modalbutton = styled.button`
background: var(--primary);
appearance: none;
border: 1px solid var(--primary);
padding: 10px 24px;
border-radius: 3px;
color: #fff;
font-weight: 600;
font-size: 1rem;
cursor: pointer;
text-align: center;
max-width: 120px;
`;

export const ModalbuttonNo = styled.button`
background: #bdbdbd;
appearance: none;
border: 1px solid #bdbdbd;
padding: 10px 22px;
border-radius: 3px;
color: #5d5d5d;
font-weight: 600;
font-size: 1rem;
cursor: pointer;
text-align: center;
max-width: 120px;
margin: 0;
margin-right: 15px;
`;
export const ModelContent = styled.div`
display: flex;
gap:1.2rem;
flex-direction: row;
align-items:flex-end;
justify-content: center;	
@media (max-width:500px){
					gap:1em;
			}
`;

export const ModelLabelValueWrapper = styled.div`
display: flex;
gap:1rem;
flex-direction: column;
justify-content: center;	
align-items:center
`;
export const ModalbuttonWrap = styled.div`
		display: flex;
    justify-content: center;
		margin: 10px 0px;
		padding-top:20px;
`;

export const ModalMultiPanelsWrap = styled.div`
		display: flex;
    flex-direction: row;
		justify-content:center;
		align-items:start;
	  gap: .65rem;

		input{
			width: 0;
			visibillity: hidden;
		}
		label{
			width: 136px;
			display: inline-flex;
		}
`;

export const MulitiPanelsSwitchWrapper = styled.div`
    display: flex;
		flex-direction: column;
		flex-wrap: wrap;
    justify-content: center;
		align-items:flex-start;
		border-left: 1px solid #DADADA;
		min-height: 80px;
		padding-left: 1.2rem;
`;
export const ModalToggle = styled.div`
		padding-top:4px;
		display: flex;
    justify-content: center;
		align-items:center;
		padding-left: 80px;
		color: #64686A;


.switchButton .button{
	width: 38px;
	height: 22px;
}
		
.checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;

	&:disabled{
		cursor: not-allowed;
	}
}

.knobs {
  z-index: 2;
  background: #DADBDC;
  border-radius: 48px;
  border: 1.5px solid #DADBDC;
}

.layer {
  width: 100%;
  transition: 0.3s ease all;
  z-index: 1;
}

#button-13 .knobs:before,
#button-13 .knobs:after,
#button-13 .knobs span {
  position: absolute;
  top: 2px;
  width:13px;
  height: 12px;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  border-radius: 2px;
  transition: 0.3s ease all;
}
#button-13 .knobs span {
	top:2px
}

#button-13 .knobs:before,
#button-13 .knobs:after {
  color: #4e4e4e;
  z-index: 1;
}

#button-13 .knobs:before {
  content: "";
  left: 7px;
  visibility: hidden;
  display: none;
  top: 5px;
}

#button-13 .knobs:after {
  content: "";
  right: 12px;
    top: 1px;
}
		
#button-13 .knobs span {
  width: 16px;
  height: 16px;
  left: 2px;
  background-color: #fff;
  z-index: 2;
  border-radius: 100%;
}

#button-13 .checkbox:checked + .knobs span {
   left: 19px;
  background-color: #fff;
}
#button-13 .checkbox:checked + .knobs:before {
	content: '';
  visibility: visible;
  display: block;
  color: #fff;
}
#button-13 .checkbox:checked + .knobs {
 background-color: #003CFF;
 border: 1.5px solid #003CFF;
}
#button-13 .checkbox:checked + .knobs:after {
  visibility: hidden;
  display: none;
}

#button-13 .checkbox~ .layer {
  background-color: #fff;
  border-radius:48px ;
}
`;
