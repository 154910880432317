import { DESIGN_MODES, IMAGERY_PROVIDER } from '../../../../constants';

export function getInitialState() {
	return {
		allPanels: [],
		jpgUrl: '',
		latLng: '',
		boundingBox: {
			ne: {
				latitude: 0,
				longitude: 0,
			},
			sw: {
				latitude: 0,
				longitude: 0,
			},
		},
		selectedPanels: [],
		uuid: '',
		externalId: '',
		imgWidth: 0,
		imgHeight: 0,
		rasterSolarPanels: [],
		offsetIndex: {
			offset120: 0,
			offset150: 0,
		},
		panel: {
			wattage: 0,
			key: '',
			height: 0,
			width: 0,
			panelName: '',
		},
		roofSegs: {},
		roofSegsFromWhichUserHasSelectedPanels: [],
		org: {
			setting: {
				showToggleForAllPanels: false,
				acceptsAnnualEnergy: false,
				canEdit: false,
				canToggle3d: true,
				enableOffsetWarning: false,
				isB2C: false
			},
			panelKeys: []
		},
		showAllPanels: false,
		selectedPanelKey: '',
		allRoofSegs: [],
		imagerySource: IMAGERY_PROVIDER.SUNROOF as ImagerySource,
		// isSyncedWithParent: false,
		mode: DESIGN_MODES.DEFAULT,
		obstructions: [],
		isSunroofAvailable: false,
		missingRoofPolygon: false,
		editableFacets: [], 
		enableEditFacet: false,
		isEdited: false,
		isSelected: false,
		facetHistory: [],
		currentIndex: -1,
		createdAt: '',
		updatedAt: '',
		imageryCapturedDate: null,
		solarDataCapturedDate: null,
	};
}